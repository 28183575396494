<template>
  <div>
    <div class="row center-md section-header">
      <div class="col-xs-12 col-md-12">
        <div class="row middle-sm middle-md top-section-header">
          <div class="left-section-header">
            <div 
              v-if="!noTitle"
              class="details-container" 
            >
              <div class="section-icon">
                <slot name="logo"></slot>
              </div>
              <div class="details">
                <h1 class="title">
                  <slot name="title"></slot>
                </h1>
                <div class="subtitle">
                  <slot name="subtitle"></slot>
                </div>
              </div>
            </div>
            <slot></slot>
            <div 
              v-if="isleftSearchInput"
              class="input-container left" 
            >
              <base-input 
                class="search" 
                label="search_title" 
                type="text" 
                :modelValue="search" 
                :placeholder="mobile ? 'search' : searchPlaceholder"
                :icon="searching? 'fa fa-cog fa-spin': 'fa fa-search'" 
                size="sm"
                :clearable="true"
                @update:modelValue="setSearch" 
              />
            </div>
          </div>
          <div 
            v-if="hasRightSection" 
            class="right-section"
          >
            <template v-if="searchEnabled && (hasDefaultSlot || hasTitleSlot) && isSingleSection && !isleftSearchInput">
              <base-input
                class="search"
                label="search_title"
                type="text"
                :modelValue="search"
                :placeholder="mobile ? 'search' : searchPlaceholder"
                :icon="searching? 'fa fa-cog fa-spin': 'fa fa-search'"
                size="sm"
                clearable
                @update:modelValue="setSearch"
              />
            </template>
            <slot
              name="view"
            ></slot>
            <div 
              v-if="hasSortSlot && !mobile" 
              class="sort"
            >
              <slot name="sort"></slot>
            </div>
            <div class="action">
              <slot name="rhs"></slot>
            </div>
          </div>
        </div>

        <div 
          v-if="hasControlsSlot" 
          class="row extra"
        >
          <slot name="controls"></slot>
        </div>
      </div>
      <div
        v-if="isFilterContainer"
        class="filtering-container"
      >
        <div
          v-if="isRightQuickSearch"
          class="col-xs-12 col-md-12 org-search-bar"
        >
          <div class="label">
            <span>
              {{ $t('quick_search') }}
            </span>
          </div>
          <base-input
            class="search"
            label="search_title"
            type="text"
            :modelValue="search"
            :placeholder="mobile ? 'search' : searchPlaceholder"
            :icon="searching? 'fa fa-cog fa-spin': 'fa fa-search'"
            size="md"
            clearable
            @update:modelValue="setSearch"
          />
        </div>
        <div
          v-if="isFilters"
          class="multi-filter"
        >
          <div class="label indent-left">
            <span>
              {{ $t('filtering_criteria') }}
            </span>
          </div>
          <multi-criteria-filter
            :filters="filters"
            @refresh="emitRefresh"
          >
          </multi-criteria-filter>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, isMobileMode } from '@/utils';

export default {
  name: 'SectionHeader',
  props: {
    noTitle: {
      type: Boolean,
    },
    searchPlaceholder: {
      type: String,
      default: 'search',
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    sortingEnabled: {
      type: Boolean,
      default: false,
    },
    searching: {
      type: Boolean,
    },
    isSingleSection: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    isleftSearchInput: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['search', 'refresh'],
  data() {
    return {
      search: ''
    };
  },
  computed: {
    hasRightSection() {
      return this.hasSortSlot || this.hasViewSlot || this.hasRhsSlot || this.searchEnabled;
    },
    isSearchInLeftSection() {
      return this.searchEnabled && !this.hasDefaultSlot && !this.hasTitleSlot;
    },
    mobile() {
      return isMobileMode();
    },
    leftWidthSize() {
      return this.hasDefaultSlot || this.isSearchInLeftSection ? 4 : 6;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasControlsSlot() {
      return !!this.$slots.controls;
    },
    hasSortSlot() {
      return !!this.$slots.sort;
    },
    hasViewSlot() {
      return !!this.$slots.view;
    },
    hasRhsSlot() {
      return !!this.$slots.rhs;
    },
    isFilterContainer() {
      return this.isRightQuickSearch || this.isFilters;
    },
    isRightQuickSearch() {
      return this.searchEnabled && (this.hasDefaultSlot || this.hasTitleSlot) && !this.isSingleSection;
    },
    isFilters() {
      return this.filters?.length > 0;
    }

  },
  watch: {
    $route() {
      const q = this.$route.query.q;
      if (this.search !== q) {
        this.search = q;
      }
    },
  },
  created() {
    this.debouncedEmitSearch = debounce(this.emitSearch, 250);
    if (this.searchEnabled) {
      const q = (this.$route.query || {}).q;
      if (q !== undefined && q !== null) {
        this.setSearch(q);
      }
    }
  },
  methods: {
    emitRefresh($event) {
      this.$emit('refresh', $event);
    },
    setSearch(v) {
      this.search = v;
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, q: v },
      });
      this.debouncedEmitSearch(v);
    },
    emitSearch(v) {
      this.$emit('search', v);
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins.scss';


.row > .search {
  justify-content: flex-start;
}

.filtering-container {
  padding-top: 15px;
  width: 100%;

  .org-search-bar {
    float: left;
    width: 240px;
    margin-top: 3px;
    margin-right: 130px;
  }

  .multi-filter {
    float: left;
    width: 610px;
  }
}

.sort :deep(.select, input) {
  min-width: 175px;
  &:not(.no-border) {
    border: 1px solid var(--plain-dark);
  }
}

.sort {
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
}

.action {
  @include not-phone {
    padding-left: 5px;
  }
}

.sort .base-select {
  min-width: 180px;
}

.sort > div {
  width: 100%;
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
}

.section-icon {
  :slotted(img) {
    width: 40px;
  }

  :slotted(em) {
    font-size: 28px;
  }
}

.section-header:first-child {
  padding: 16px 32px;
}

.section-header {
  &:not(.extra) {
    min-height: 64px;
  }
  border-bottom: 1px solid var(--list-header-border);
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  @include phone {
    padding: 5px;
  }
}

.details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;

  .details {
    margin-left: 10px;
    text-align: left;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
    color: var(--detail-text);
  }
}

.input-container {
  min-width: 180px;
}

.input-action-container {
  display:inline-flex;
  @include phone {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.input-control {
  @include not-phone {
    width: 320px;
  }
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.label {
  color: var(--list-header-text);
}

.indent-left {
  margin-left: 50px;
}

.left-section-header {
  display:flex;
  align-items:center;
  flex: 1;
}
</style>
