import store from '@/store';
import router from '@/router';
import {buildServicePermission} from '@/utils/permissions';

function match(permissions, permissionToMatch) {

  if (permissionToMatch.startsWith("root:")) {
    if (store.getters.selectedOrganization.parent != null) {
      return false;
    }
    permissionToMatch = permissionToMatch.replace("root:", "");
  }
  if (permissions.includes(permissionToMatch)) {
    return true;
  }
  const partsToMatch = permissionToMatch.split(':');
  return permissions.some((permission) => {
    const permissionParts = permission.split(':');
    return permissionParts.every((permissionPart, index) =>
      permissionPart === '*' || permissionPart === partsToMatch[index]);
  });
}

/* Array<Permission>, Array<Permission> -> boolean */
function matchesAll(permissions, permissionsToMatch, every = true) {
  if (!every) {
    return permissionsToMatch.some(permissionToMatch => match(permissions, permissionToMatch));
  }
  return permissionsToMatch.every(permissionToMatch => match(permissions, permissionToMatch));
}

function hasAllPermissions(perms, route) {
  const meta = route.meta;
  if (!meta || !meta.protected) {
    return true;
  }
  return matchesAll(perms, meta.protected, meta.isProtectUnion);
}

function isMemberOf(environmentId) {
  return !!store.getters.servicePermissions.find(p => p.startsWith(environmentId));
}

function getPermissions() {
  return [
    ...store.getters.systemPermissions,
    ...store.getters.servicePermissions,
    ...store.getters.globalPermissions,
    ...store.getters.subsPermissions,
  ];
}

function matchesAny(permissions) {
  return matchesAll(permissions, getPermissions(), false);
}

const checkPermissions = (p) => {
  const permissions = getPermissions();
  if (!permissions || !permissions.length) {
    return false;
  }
  return match(permissions, p);
};

export default {
  matchesAny,
  isMemberOf,
  isPermittedRoute(route) {
    const permissions = getPermissions();
    // Support route records from router.beforeEach and string routes from v-bind:to
    let matches;
    let r;
    if (route.matched) {
      matches = route.matched;
    } else {
      r = router.resolve(route);
      if (!r || !r.matched) {
        // If the route doesn't resolve then you're permitted, but it'll 404
        return true;
      }
      matches = r.matched;
    }
    return matches.every(rt => hasAllPermissions(permissions, rt));
  },
  hasPermission(permission) {
    if (!permission) {
      return true;
    }
    let permissions = [];
    let or = false;
    if (typeof permission === 'string') {
      permissions = [permission];
    } else if (Array.isArray(permission)) {
      permissions = [...permission];
    } else {
      permissions = permission.permissions;
      or = permission.or;
    }
    if (or) {
      return !!permissions.find(p => checkPermissions(p));
    }
    return !permissions.find(p => !checkPermissions(p));
  },
  currentOrgOwnsServiceConnection(serviceConnection) {
    const loadedServiceConnection = store.getters.serviceConnections.find(sc => sc.serviceCode === serviceConnection.serviceCode);

    if (!loadedServiceConnection || !store.getters.myOrganization) {
      return false;
    }
    
    const serviceConnectionOrg = loadedServiceConnection.organizationId;
    const currentOrg = store.getters.myOrganization.id;
    const hasAdminEnvPermission = match(store.getters.systemPermissions || [], 'admin:envs');

    return (serviceConnectionOrg === currentOrg) && hasAdminEnvPermission;
  },
  hasOperationPermission(env, entityType, op) {
    let envId = env.id;

    if (!isMemberOf(envId)) {
      if (this.currentOrgOwnsServiceConnection(env.serviceConnection)) {
        return true;
      }

      if (
        match(store.getters.systemPermissions || [], 'admin:envs')
        && !this.hasPermission("sys:supportViewer")
        && (!env.restricted || this.hasPermission("reseller:envs") || this.hasPermission("admin:readEnvs"))
      ) {
        return true;
      }
    }

    const servicePermissions = store.getters.servicePermissions;
    const permission = buildServicePermission(envId, entityType, op);
    return match(servicePermissions || [], permission);
  },
  match,
};
