// @ts-nocheck
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import {createApp} from 'vue';
import {VTooltip, Tooltip} from 'floating-vue';
import VueHighlightJS from 'vue3-highlightjs';
import VueTour from 'vue3-tour';

import App from './app/App';

import router from './router';
import socket from './socket';
import socketHandlers from './socket/handlers';

import store from './store';
import i18n from './i18n';
import {initializeNotificationWatcher} from './utils/notify/notifier';

import Components from './components';
import NextGenComponents from './components/nextgen';
import CustomDirectives from './directives';

import {DateFormatter} from './utils/dates/formatter';

const meta = document.createElement('meta');
meta.name = 'version';
meta.content = process.env.VUE_APP_VERSION;
document.getElementsByTagName('head')[0].appendChild(meta);

const app = createApp(App)

app.use(Components);
app.use(CustomDirectives);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueHighlightJS);
app.use(VueTour);
app.use(DateFormatter);
app.use(NextGenComponents);
app.directive('tooltip', VTooltip)
app.component('VTooltip', Tooltip);

initializeNotificationWatcher();

app.config.errorHandler = (err) => {
  if (err) {
    console.error(err);
  }
}

app.config.globalProperties.$tn = (label, interpolation, _default) => {
  if (!i18n.global.te(label, interpolation)) {
    return _default || label;
  }
  return i18n.global.t(label, interpolation);
};
window.dataLayer = window.dataLayer || [];

socket.registerHandlers(socketHandlers);

app.mount('#app');
/* eslint-disable no-new */
