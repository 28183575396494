import { findServiceLabel } from '../label';

const getOperationLabel = (serviceType, serviceDescriptors, entity, op, label) => {
  const fn = curType => `${curType}.service.${entity}.operations.${op.toLowerCase()}.` + (label ? label : 'name');
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getRouterLabel = (serviceType, serviceDescriptors, entity, op) => {
  const fn = curType => `${curType}.service.${entity}.reroutes.${op.toLowerCase()}.name`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getToggleLabel = (serviceType, serviceDescriptors, viewName) => {
  const fn = curType => `${curType}.service.toggle.${viewName.toLowerCase()}`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getSectionLabel = (serviceType, serviceDescriptors, entity, op, section) => {
  const fn = curType => `${curType}.service.${entity}.operations.${op.toLowerCase()}.sections.${section.toLowerCase()}`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getSectionDescriptionLabel = (serviceType, serviceDescriptors, entity,
  op, section) => {
  const fn = curType => `${curType}.service.${entity}.operations.${op.toLowerCase()}.sections.${section.toLowerCase()}_description`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getSectionExpandLabel = (serviceType, serviceDescriptors, entity,
  op, section) => {
  const fn = curType => `${curType}.service.${entity}.operations.${op.toLowerCase()}.sections.${section.toLowerCase()}_expand`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getEntityLabel = (serviceType, serviceDescriptors, entity) => {
  const fn = curType => `${curType}.service.${entity}.name`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getRoleLabel = (serviceType, serviceDescriptors, role) => {
  const fn = curType => `${curType}.roles.${role}`;
  const label = findServiceLabel(serviceType, serviceDescriptors, fn, null);
  if (!label) {
    return `env_roles.${role}`;
  }
  return label;
};

const getApiInfoFieldLabel = (serviceType, serviceDescriptors, field) => {
  const fn = curType => `${curType}.api_info.${field}`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

const getEmptyListLabel = (serviceType, serviceDescriptors, entity) => {
  const fn = curType => `${curType}.service.${entity}.empty_list`;
  return findServiceLabel(serviceType, serviceDescriptors, fn, null);
};

function getConnectionByServiceCode(store, serviceCode) {
  return store.getters.serviceConnections.find(sc => sc.serviceCode === serviceCode);
}

const getUsageLabel = (serviceDescriptors, serviceUsageKey) => {
  const [serviceType, ...fieldBits] = serviceUsageKey.split('.');
  const field = fieldBits.join('.');
  const fn = curType => `usage.${curType}.${field}`;
  const label = findServiceLabel(serviceType, serviceDescriptors, fn, null);
  return label || serviceUsageKey;
};

const getConnectionIconUrl = (serviceConnection) => {
  // Avoid call if the service connection is not set properly
  if (!serviceConnection.type) {
    return undefined;
  }
  const defaultUrl = `/rest/plugins/${serviceConnection.type}/logo`;
  return serviceConnection.iconOverrideUrl ? serviceConnection.iconOverrideUrl : defaultUrl;
};

const isOwnedByOrg = (connection, org) => connection.organization.id === org.id;
const isSameOrg = (org1, org2) => (org1.id === org2.id);
 
// checks if org1 belongs to lineage of org2
const belongsToOrgLineage = (org1, org2) => (org2.lineage.includes(org1.id));

export {
  getOperationLabel,
  getRouterLabel,
  getConnectionByServiceCode,
  getEntityLabel,
  getRoleLabel,
  getApiInfoFieldLabel,
  getEmptyListLabel,
  getSectionLabel,
  getSectionDescriptionLabel,
  getSectionExpandLabel,
  getUsageLabel,
  getToggleLabel,
  getConnectionIconUrl,
  isOwnedByOrg,
  isSameOrg,
  belongsToOrgLineage,
};
