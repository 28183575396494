import { CrudApi } from './api';

class ServiceScopesApi extends CrudApi {
  constructor() {
    super('service_scopes');
  }

}

const serviceScopesApi = new ServiceScopesApi();
export default serviceScopesApi;