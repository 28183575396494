<template>
  <advanced-details
    :loading="loading"
    :titleLabel="hideTitle ? '' : 'assigned_services'"
  >
    <template #operations>
      <action-icon
        v-if="disabled && hasEditPermission"
        icon="fa fa-edit"
        tooltipLabel="edit"
        expandOnHover
        clickable
        @click="toggleForm"
      ></action-icon>
    </template>
    <empty-message
      v-if="!hasAssignedConnections && disabled"
      icon="fa fa-plug"
      :textLabel="getEmptyListLabel"
    ></empty-message>
    <OrganizationServicesForm
      v-else
      :cancelLabel="cancelLabel"
      :organization="organization"
      :disabled="disabled"
      :executing="executing"
      :availableConnections="availableConnections"
      :componentKey="componentKey"
      @submit="submit"
      @cancel="cancel"
    />
  </advanced-details>
</template>
<script>

import apis from '@/utils/apis';
import authz from '@/authz';
import notify from '@/utils/notify';

import { mapActions, mapGetters } from 'vuex';
import OrganizationServicesForm from './OrganizationServicesForm';

export default {
  name: 'ManageAssignedServices',
  components: { OrganizationServicesForm },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    supportsReadonly: {
      type: Boolean,
      default: true,
    },
    cancelLabel: {
      type: String,
    },
    hideTitle: {
      type: Boolean,
    },
    navigateTo: {
      type: String,
    },
  },
  emits: ['submit', 'skip', 'refreshOrg'],
  data() {
    return {
      disabled: this.supportsReadonly,
      executing: false,
      availableConnections: [],
      loading: true,
      componentKey: 0,
      hasEditPermission: false,
    };
  },
  computed: {
    hasAssignedConnections() {
      const availableConnectionIds = this.availableConnections.map(a => a.id);
      return this.organization.serviceConnections
        .filter(sc => availableConnectionIds.includes(sc.id)).length > 0;
    },
    getEmptyListLabel() {
      return 'no_assigned_connections';
    },
    ...mapGetters([
      'selectedOrganization',
      'myOrganization',
    ]),
  },
  async created() {
    await Promise.all([
      this.fetchAvailableConnections(),
      this.setCanEdit()
    ])
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'updateCurrentOrgAttributes',
    ]),
    async fetchAvailableConnections() {
      const resp = await apis.organizations.findServiceConnections(this.organization.id);
      if (resp.status !== 200 || !resp.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.availableConnections = resp.data;
      }
    },
    toggleForm() {
      if (this.supportsReadonly) {
        this.disabled = !this.disabled;
      }
    },
    isAssigned(connection) {
      return this.organization.serviceConnections.filter(c => c.id === connection.id).length > 0;
    },
    async updateOrg(org) {
      const resp = await apis.organizations.update(this.organization.id, org);
      if (resp && resp.errors) {
        notify.error(this.$t('org_update_failure'));
        this.componentKey += 1;
      } else {
        this.$emit('refreshOrg');
        if (this.selectedOrganization.id === this.organization.id) {
          this.updateCurrentOrgAttributes({ serviceConnections: resp.data.serviceConnections });
        }
      }
      return resp;
    },
    async assignQuotas(quotasToAssign) {
      const assigns = await Promise.all(
        quotasToAssign.map(q => apis.quotas.assignQuota(q.id, this.organization.id)),
      );
      const quotaAssignErrors = assigns.filter(a => a.error).map(a => a.errors);
      if (quotaAssignErrors.length > 0) {
        notify.error(this.$t('org_quota_apply_failed'));
      }
      return quotaAssignErrors;
    },
    async submit(connections) {
      this.executing = true;
      const quotasToAssign = connections.filter(c => c.quotas.length > 0).map(c => c.quotas[0]);
      const connectionsStrippedQuotas = connections.filter(c => !this.isAssigned(c)).map(c => ({
        ...c,
        quotas: null,
      }));
      const org = {
        ...this.organization,
        users: null,
        quotas: null,
        tags: this.organization.tags ?
          this.organization.tags.filter(t => !t.system) : this.organization.tags,
        serviceConnections: connectionsStrippedQuotas,
      };

      const resp = await this.updateOrg(org);
      if (!resp.errors) {
        await this.assignQuotas(quotasToAssign);
      }

      this.executing = false;
      this.disabled = true;

      if (this.navigateTo) {
        this.$router.navigateBackOrDefault({ name: this.navigateTo });
      } else {
        this.$emit('submit');
      }
    },
    cancel() {
      if (this.supportsReadonly) {
        this.disabled = true;
      }
      this.$emit('skip');
    },
    async setCanEdit() {
      const orgPermissions = await apis.users.getEffectivePermissions(this.organization.id);
      const allPermissions = orgPermissions ? [
        ...(orgPermissions.system || []),
        ...(orgPermissions.subs || []),
        ...(orgPermissions.service || []),
        ...(orgPermissions.global || [])
      ]
        : [];

      this.hasEditPermission = authz.hasPermission('reseller:connections') ||
        authz.hasPermission('reseller:assignConnections') ||
        allPermissions.some(p => p === 'reseller:assignConnections' || p === 'reseller:connections');
    },
  },
};
</script>
