import { CrudApi } from './api';

class RoleApi extends CrudApi {
  constructor() {
    super('roles');
  }
}

const roleApi = new RoleApi();

export default roleApi;
