import Api from './api';
import auth from './auth';
import authz from './authz';
import billable from './billable';
import hidden from './hidden';
import i18n from './i18n';
import users from './users';
import activity from './activity';
import environments from './environments';
import feedback from './feedback';
import feedbackSettings from './feedbackSettings';
import organizations from './organizations';
import serviceConnections from './serviceConnections';
import serviceDescriptors from './serviceDescriptors';
import serviceScopes from './serviceScopes';
import metrics from './metrics';
import roles from './roles';
import views from './views';
import operations from './operations';
import configProperties from './configProperties';
import systemInfo from './systemInfo';
import plugins from './plugins';
import helpCenter from './helpCenter';
import htmlSnippets from './htmlSnippets';
import emailTemplates from './emailTemplates';
import permissions from './permissions';
import apiKeys from './apiKeys';
import usageSummary from './usageSummary';
import quotas from './quotas';
import properties from './properties';
import notifications from './notifications';
import cache from './cache';
import categories from './categories';
import trials from './trials';
import support from './support';
import scheduler from './scheduler';
import resourceCommitments from './resourcecommitments';
import customFields from './customfields';
import pricingsV1 from './pricingsV1';
import pricings from './pricings';
import tags from './tags';
import reports from './reports';
import productCatalogs from './productCatalogs';
import notificationCategories from './notificationCategories';
import healthCheck from './healthCheck';
import knowledgeBase from './knowledgeBase';
import branding from './branding';
import infra from './infra';
import accounts from './accounts';
import pricingPackages from './pricingPackages';
import identityProviders from './identityProviders';
import serviceProviders from './serviceproviders';
import samlSettings from './samlSettings';
import trialsSettings from './trialsSettings';
import emailSettings from './emailSettings';
import dashboard from './dashboard';
import billingProviders from './billingProviders';
import passwordPolicies from './passwordPolicies';
import billingSettings from './billingSettings';
import dashboardSettings from './dashboardSettings'
import invoices from './invoices';
import entities from './entities';
import taxProviders from './taxProviders';
import features from './features';
import payments from './payments';
import rollbackTriggers from './rollbackTriggers';
import discounts from './discounts';
import invoiceConfig from './invoiceConfig';
import commitments from './commitments';
import tasks from './tasks';
import docs from './docs';
import insights from './insights';
import enterpriseRegistrations from './enterpriseRegistrations';
import termsOfService from "./termsOfService";
import apiDocsCustomization from './apiDocsCustomization';
import usageGenerations from './usageGenerations';
import sshkeys from './sshkeys';
import oauth from './oauth';
import disclaimers from './disclaimers';
import userWorkspacePreference from './userWorkspacePreferences';

const apis = {
  accounts,
  auth,
  authz,
  billable,
  hidden,
  i18n,
  users,
  activity,
  entities,
  environments,
  feedback,
  feedbackSettings,
  serviceConnections,
  serviceDescriptors,
  serviceScopes,
  metrics,
  views,
  operations,
  organizations,
  configProperties,
  systemInfo,
  plugins,
  apiKeys,
  roles,
  helpCenter,
  htmlSnippets,
  emailTemplates,
  usageSummary,
  quotas,
  permissions,
  pricings,
  pricingsV1,
  productCatalogs,
  properties,
  notifications,
  cache,
  categories,
  scheduler,
  resourceCommitments,
  support,
  reports,
  tags,
  trials,
  customFields,
  notificationCategories,
  healthCheck,
  knowledgeBase,
  branding,
  infra,
  pricingPackages,
  identityProviders,
  serviceProviders,
  samlSettings,
  trialsSettings,
  emailSettings,
  dashboard,
  billingProviders,
  passwordPolicies,
  invoices,
  billingSettings,
  taxProviders,
  features,
  payments,
  rollbackTriggers,
  discounts,
  invoiceConfig,
  commitments,
  tasks,
  docs,
  dashboardSettings,
  insights,
  enterpriseRegistrations,
  termsOfService,
  apiDocsCustomization,
  usageGenerations,
  sshkeys,
  oauth,
  disclaimers,
  userWorkspacePreference
};

/**
 * Sets the Vuex store on all Api instances. This is done to avoid circular dependency.
 * @param {*} Vuex store
 */
apis.use = (store) => {
  const keys = Object.keys(apis);
  for (let i = 0; i < keys.length; i += 1) {
    const field = apis[keys[i]];
    if (field instanceof Api) {
      field.use(store);
    }
  }
};

export default apis;
