<template>
  <list-row 
    v-if="!readonly || assigned" 
    noActions
  >
    <div 
      v-if="!readonly" 
      class="assign-connection-checkbox base-checkbox-wrapper"
    >
      <base-checkbox 
        v-if="!assigned" 
        v-model="selected" 
        :disabled="!reachable || !isAssignable" 
        @update:modelValue="toggleConnection"
      ></base-checkbox>
    </div>
    <list-column allowOverflow>
      <div class="multiline">
        <div class="row">
          <div class="primary">
            <service-label :connection="connection"></service-label>
          </div>
          <base-tooltip 
            direction="right" 
            :message="$t(connectionTooltipMessage)"
          >
            <base-icon 
              icon="fa fa-circle status panel-icon" 
              :class="connectionTooltipColor"
            />
          </base-tooltip>
        </div>
      </div>
    </list-column>
    <list-column>
      <div 
        v-if="assigned" 
        class="state-container"
      >
        <base-state-badge 
          :state="connection.state" 
          entityType="connection"
          :entityName="connection.name"
          :overrideState="overrideStatus.statusLabel"
          :overrideStateColor="overrideStatus.statusColor"
        />
      </div>
    </list-column>
    <list-column allowOverflow>
      <div v-if="connection.quotas.length > 0">
        <base-select 
          v-model="quotaId" 
          :disabled="editQuotaDisabled()" 
          :items="quotas" 
          @update:modelValue="updateConnection"
        ></base-select>
      </div>
    </list-column>
    <list-column allowOverflow>
      <div v-if="supportsOrganizationLinking">
        <div v-if="hasAssignedOrganization">
          {{ assignedOrganizationName }}
        </div>
        <div v-else-if="hasAssignableOrganizations && !connectionStatePending">
          <base-select
            v-model="backendOrganizationId"
            :items="assignableOrganizationOptions"
            @update:modelValue="updateConnection"
          ></base-select>
        </div>
      </div>
    </list-column>
  </list-row>
</template>
<script>

import { getConnectionIconUrl } from '@/utils/service';
import { mapGetters } from 'vuex';
import authz from '@/authz';

export default {
  name: 'ConnectionAssignmentRow',
  props: {
    connection: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    assigned: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['addConnection', 'removeConnection', 'updateConnection'],
  data() {
    return {
      quotaId: this.connection.quotaId,
      initiallyAssigned: false,
      selected: this.assigned,
      backendOrganizationId: this.connection.assignedOrganization?.id,
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
    ]),
    quotaDisabled() {
      return this.readonly || !this.selected || !this.reachable;
    },
    image() {
      return getConnectionIconUrl(this.connection);
    },
    quotas() {
      return this.connection.quotas.map(q => ({
        value: q.id,
        label: q.name,
      }));
    },
    reachable() {
      return (this.connection.status && this.connection.status.reachable);
    },
    status() {
      return this.connection.reachable ? 'green' : 'red';
    },
    isAssignable() {
      return this.connection.isAssignable;
    },
    assignableOrganizations() {
      return (this.connection.assignableOrganizations) || [];
    },
    hasAssignableOrganizations() {
      return this.assignableOrganizations.length > 0;
    },
    shouldProvideCreateOption() {
      return this.supportsOrganizationCreation 
          && this.supportsOrganizationLinking 
          && this.hasAssignableOrganizations;
    },
    assignableOrganizationOptions() {
      const options = this.assignableOrganizations.map(o => ({
        value: o.id,
        label: o.name,
      }));
      const createOption = [{
          value: null,
          label: 'add_new_customer',
        }]
      if (this.shouldProvideCreateOption) {
        return [...createOption, ...options];
      }
      return options;
    },
    assignedOrganization() {
      return this.connection.assignedOrganization;
    },
    assignedOrganizationName() {
      return (this.connection.assignedOrganization?.name) || '';
    },
    hasAssignedOrganization() {
      return !!this.assignedOrganization
    },
    overrideStatus() {
      return (this.connection.overrideStatus) || {}
    },
    shouldOverrideStatus() {
      return !!(this.connection.overrideStatus?.statusLabel || this.connection.overrideStatus?.statusColor)
    },
    supportsOrganizationLinking() {
      return this.connection.supportsOrganizationLinking;
    },
    supportsOrganizationCreation() {
      return this.connection.supportsOrganizationCreation;
    },
    connectionTooltipMessage() {
     if (!this.reachable) {
      return 'unreachable';
     }

     if (this.shouldOverrideStatus) {
        return (this.connection.overrideStatus?.statusLabel) || 'connected';
     }
     return 'connected';
    },
    connectionTooltipColor() {
      if (!this.reachable) {
        return { red: true};
      }

      if (this.shouldOverrideStatus 
            && this.connection.overrideStatus?.statusColor) {
        const k = this.connection.overrideStatus?.statusColor.toLowerCase() || 'green';
        return { [k] : true }
      }
      return { green: true};
    },
    connectionState() {
      return this.connection.state;
    },
    connectionStatePending() {
      return this.connectionState == 'PENDING' || this.connectionState == 'PROVISIONING';
    }
  },
  methods: {
    toggleConnection(added) {
      if (added) {
        this.$emit('addConnection', {
          id: this.connection.id,
          quotas: [{ id: this.quotaId }].filter(q => q.id),
          backendOrganizationId: this.backendOrganizationId,
        });
      } else {
        this.$emit('removeConnection', this.connection.id);
      }
    },
    updateConnection() {
      if (this.selected) {
        this.$emit('updateConnection', {
          id: this.connection.id,
          quotas: [{ id: this.quotaId }].filter(q => q.id),
          backendOrganizationId: this.backendOrganizationId,
        });
      }
    },
    editQuotaDisabled() {
      return this.quotaDisabled || !authz.hasPermission('reseller:connections');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixins.scss";
.status {
  margin-left: 5px;
  vertical-align: middle;
}
.multiline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  img {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }

  .primary {
    color:#5b5b5b;
    text-align: left;
    @include not-phone {
      max-width: 20vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .detail {
    margin-top: 3px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color:#969696;
  }
}

:deep(.base-select) {
  .select {
    min-width: 150px;
  }
}
.assign-connection-checkbox {
  align-self: center;
}

.base-checkbox-wrapper {
  width: 40px;
  min-width: 40px;
}
:deep(.popover) {
    min-width: fit-content;
}
</style>
