<template>
  <form-page
    :title="steps[stepNumber].label"
    hideSubmit
    hideCancel
  >
    <MultiStep
      :currentStep="stepNumber"
      :steps="steps"
    >
      <transition
        name="multi-step-fade"
        mode="out-in"
      >
        <OrganizationForm
          v-if="stepNumber == 0"
          :executing="executing"
          :organization="organization"
          :errors="errorsObj"
          hideBillableToggle
          @updateEntryPointError="updateEntryPointError"
          @submit="addOrg"
        />
        <ManageAssignedServices
          v-else-if="stepNumber == 1"
          v-permission.or="resellerConnections"
          hideTitle
          :supportsReadonly="false"
          :organization="organization"
          :availableConnections="availableConnections"
          @skip="cancel"
          @submit="goToNextStep"
        />
        <OrganizationBillingForm
          v-else-if="stepNumber == 2"
          v-permission="'reseller:organizationBilling'"
          :organization="organization"
          @submit="updateBillableOrgInfo"
        />
      </transition>
    </MultiStep>
  </form-page>
</template>

<script>
import { mapGetters } from 'vuex';
import apis from '@/utils/apis';
import errors from '@/utils/errors';
import notify from '@/utils/notify';
import authz from '@/authz';
import MultiStep from '@/app/Main/components/MultiStep';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import OrganizationForm from './OrganizationForm';
import ManageAssignedServices from './services/ManageAssignedServices';
import OrganizationBillingForm from '@/app/Main/Organizations/OrganizationBillingForm.vue';
import { boldify } from '@/utils';

const RESELLER_CONNECTIONS = 'reseller:connections';
const RESELLER_ASSIGN_CONNECTIONS = 'reseller:assignConnections';
const RESELLER_ORGANIZATION_BILLING = 'reseller:organizationBilling';

export default {
  name: 'AddOrganization',
  components: { OrganizationBillingForm, OrganizationForm, MultiStep, ManageAssignedServices },
  mixins: [orgSwitchNavigationMixin],
  props: {
    // the parent id
    id: {
      type: String,
    },
  },
  data() {
    return {
      resellerConnections: [RESELLER_CONNECTIONS, RESELLER_ASSIGN_CONNECTIONS],
      organization: {
        parent: { id: this.id },
        name: null,
        entryPoint: null,
        isBillable: false,
        billableStartDate: null,
        billingDay: 1,
        billingMode: 'MANUAL',
        serviceConnections: [],
        tags: [],
      },
      availableConnections: [],
      executing: false,
      errorResponse: {},
      stepNumber: 0,
    };
  },
  computed: {
    ...mapGetters([
      'myOrganization',
      'selectedOrganization',
    ]),
    steps() {
      let steps = [{
        label: this.$t('add_organization'),
        successLabel: this.$t('add_organization'),
        name: 'create',
      }, {
        label: this.$t('assign_services'),
        successLabel: this.$t('assign_services'),
        name: 'services',
      }].filter(s => s.name !== 'services' || authz.hasPermission(RESELLER_CONNECTIONS) || authz.hasPermission(RESELLER_ASSIGN_CONNECTIONS));

      if (this.hasBillingPermission()) {
        steps.push({
          label: this.$t('billing'),
          successLabel: this.$t('billing'),
          name: 'billing',
        });
      }

      return steps;
    },
    errorsObj() {
      const err = {};
      errors.parse(this.$t.bind(this), this.errorResponse, err);
      return { ...err, customFieldErrors: this.errorResponse.errors };
    },
  },
  async created() {
    const connectionsResp = await apis.serviceConnections.list();
    if (connectionsResp.ok) {
      this.availableConnections = connectionsResp.data;
    }
  },
  methods: {
    goToNextStep() {
      if (this.hasBillingPermission()) {
        this.stepNumber += 1;
      } else {
        this.$router.navigateBackOrDefault({ name: 'organizationList' });
      }
    },
    cancel() {
      this.$router.navigateBackOrDefault({ name: 'organizationList' });
    },
    updateEntryPointError(isValid) {
      this.errors.entryPoint = isValid ? null : this.$t('entry_point_not_available');
    },
    hasBillingPermission() {
      return authz.hasPermission(RESELLER_ORGANIZATION_BILLING) && (this.organization.id !== this.myOrganization.id
        || this.isRootOrg) && !this.organization.isTrial;
    },
    isRootOrg() {
      return !this.organization.parent;
    },
    async addOrg(org, billableOrgInfo) {
      this.executing = true;
      const hypotheticalChildOrg = {
        ...org,
        parent: { id: this.id || this.selectedOrganization.id },
      };
      const resp = await apis.organizations.create(hypotheticalChildOrg);
      if (resp && resp.errors) {
        this.errorResponse = resp;
        // clean up selection
        this.organization.serviceConnections = [];
      } else {
        this.organization = resp.data;
        if (this.organization.isBillable && this.hasBillingPermission()
          && billableOrgInfo !== null) {
          await apis.organizations.setBillable(this.organization.id, billableOrgInfo);
          if (resp && resp.errors) {
            notify.error(this.$t('reports.unexpected_error_billable'));
          }
        }

        this.proceedToNextStep();
      }
      this.executing = false;
    },
    proceedToNextStep() {
      if (authz.hasPermission(RESELLER_CONNECTIONS) || authz.hasPermission(RESELLER_ASSIGN_CONNECTIONS)) {
        this.stepNumber += 1;
      } else {
        if (this.hasBillingPermission()) {
          this.stepNumber += 2;
        } else {
          this.$router.navigateBackOrDefault({ name: 'organizationList' });
        }
      }
    },
    async updateBillableOrgInfo(billableOrgInfo) {
      this.executing = true;

      if (this.hasBillingPermission() && billableOrgInfo !== null) {
        const res = await apis.organizations.setBillable(this.organization.id, billableOrgInfo);

        if (res && res.errors) {
          notify.error(this.$t('events.system.billable_organization_info.update.failure', { organizationName: this.organization.name }));
        } else {
          notify.success(
            this.$t('events.system.billable_organization_info.update.success', boldify({ organizationName: this.organization.name })),
            this.$t('events.system.notifications.success'));
        }
      }

      this.$router.navigateBackOrDefault({ name: 'organizationList' });

      this.executing = false;
    },
  },
};
</script>
