import i18n from '@/i18n';

const CACHED_SERVICE_LABELS = {};

const findServiceLabel = (serviceType, serviceDescriptors, fn, obj) => {
  let curType = serviceType ? serviceType.split(":")[0] : undefined;
  const mainLabel = fn(curType, obj);
  if (CACHED_SERVICE_LABELS[mainLabel]) {
    return CACHED_SERVICE_LABELS[mainLabel];
  }
  // Should store the extension types of the service type as well as its lineage
  const collectedExtensions = [];

  while (curType) {
    // If we couldn't find the label in the plugin type, we check if it resides in
    // it's parent type's labels
    const label = fn(curType, obj);
    if (i18n.global.te(label)) {
      CACHED_SERVICE_LABELS[mainLabel] = label;
      return label;
    }
    if (!serviceDescriptors[curType]) {
      break;
    }
    if (serviceDescriptors[curType].extensions &&
      Object.keys(serviceDescriptors[curType].extensions)) {
      collectedExtensions.push(...Object.keys(serviceDescriptors[curType].extensions));
    }
    curType = serviceDescriptors[curType].parentType;
  }

  if (serviceDescriptors[serviceType]) {
    // Finally, if no labels have been found we check if there are any
    // extensions to the plugin and check for the label there
    for (let i = 0; i < collectedExtensions.length; i += 1) {
      const extensionName = collectedExtensions[i];
      const label = fn(extensionName, obj);
      if (i18n.global.te(label)) {
        CACHED_SERVICE_LABELS[mainLabel] = label;
        return label;
      }
    }
  }
  return null;
};

export { findServiceLabel };
