<template>
  <div>
    <base-loader v-if="loading" />
    <base-details 
      v-else
      :name="organization.name"
      :description="entryPoint"
      :summaryColumns="summary"
    >
      <template #identity>
        <base-image 
          v-if="organization.image" 
          :src="organization.image" 
          height="40px" 
          :altText="organization.name" 
        />
      </template>
      <template #rhs>
        <OrganizationActions 
          :organization="organization" 
          :administrableOrgs="administrableOrgs" 
          hideViewAction  
          @deleted="$emit('deleted', $event)" 
          @refresh="$emit('refreshOrg', $event)"
        />
      </template>
      <template #activity="slotProps">
        <div 
          v-if="slotProps.summary.sparkline"
          class="sparkline" 
        >
          <sparkline-chart :series="slotProps.summary.sparkline.activity"></sparkline-chart>
        </div>
      </template>
      <template #tags="slotProps">
        <div v-if="slotProps.summary.tags">
          <base-tags :tags="slotProps.summary.tags"></base-tags>
        </div>
      </template>
      <template #details>
        <template v-if="organization.users && organization.users.length > 0">
          <div class="secondary-title">
            <h2>{{ $t('users') }}</h2>
          </div>
          <OrganizationUsers :users="organization.users" />
        </template>
        <base-attributes :attributes="attributes">
          <template #default="slotProps">
            <copyable-field 
              :modelValue="slotProps.att.value" 
              :disabled="!slotProps.att.copyable" 
              :onHover="!mobile"
            >
              <base-tags v-if="slotProps.att.isTags">
                <div 
                  v-for="tag in slotProps.att.value"
                  :key="tag.id" 
                  class="attribute-tags"
                >
                  <base-tooltip 
                    v-if="tag.isOIDC" 
                    direction="bottom" 
                    titleLabel="oidc"
                  >
                    <base-tag 
                      :tag="tag.name" 
                      :isHtml="tag.isHtml" 
                      :sizeClass="tag.sizeClass" 
                      :colorClass="tag.colorClass"
                    ></base-tag>
                    <template #popover>
                      <base-list 
                        ariaLabel="oidc" 
                        noActions 
                        :ariaRowCount="oidcAuths.length"
                      >
                        <list-header>
                          <list-column>{{ $t('name') }}</list-column>
                        </list-header>
                        <list-row 
                          v-for="oidc in oidcAuths"
                          :key="oidc.id" 
                        >
                          <list-column>
                            <div class="name-plus-logo">
                              <base-image 
                                class="logo" 
                                :src="oidc.logo" 
                                :altText="oidc.displayName"
                              />
                              <span class="display-name">{{ oidc.displayName }}</span>
                            </div>
                          </list-column>
                        </list-row>
                      </base-list>
                    </template>
                  </base-tooltip>
                  <base-tag 
                    v-else 
                    :tag="tag.name" 
                    :isHtml="tag.isHtml" 
                    :sizeClass="tag.sizeClass" 
                    :colorClass="tag.colorClass"
                  ></base-tag>
                </div>
              </base-tags>
              <div v-else-if="slotProps.att.i18n">
                <div v-if="slotProps.att.value === ''">
                  <base-icon icon="fa fa-warning yellow" />
                  {{ $t('reports.missing') }}
                </div>
                <div v-else>
                  {{ $t(slotProps.att.value) }}
                </div>
              </div>
              <div v-else-if="slotProps.att.copyable">
                {{ slotProps.att.value }}
              </div>
            </copyable-field>
          </template>
        </base-attributes>
        <div 
          v-if="organization.notes"
          class="notes-container" 
        >
          <div class="secondary-title">
            <h2>{{ $t('notes') }}</h2>
          </div>
          <markdown-viewer 
            class="notes" 
            :modelValue="organization.notes"
          ></markdown-viewer>
        </div>
      </template>
    </base-details>
  </div>
</template>
<script>
import { customFieldMixin } from '@/mixins/customFieldMixin';
import { mapGetters } from 'vuex';
import { isMobileMode, sortBy } from '@/utils';
import apis from '@/utils/apis';
import authz from '@/authz';
import BaseTag from '@/components/BaseTag';
import notify from '@/utils/notify';
import SparklineChart from '@/components/echart/SparklineChart';
import OrganizationUsers from './OrganizationUsers';
import OrganizationActions from './OrganizationActions';

export default {
  name: 'OrganizationDetails',
  components: { OrganizationUsers, OrganizationActions, BaseTag, SparklineChart },
  mixins: [customFieldMixin],
  props: {
    organization: {
      type: Object,
    },
    administrableOrgs: {
      type: Object,
    },
  },
  emits: ['deleted', 'refreshOrg'],
  data() {
    return {
      customFields: [],
      identityProviders: [],
      billableOrgInfo: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['locale', 'selectedOrganization']),
    entryPoint() {
      return this.organization.entryPoint.toLowerCase() === this.organization.name.toLowerCase()
        ? null : this.organization.entryPoint;
    },
    mobile() {
      return isMobileMode();
    },
    activity() {
      return this.organization.activity || [];
    },
    customFieldAttributes() {
      return (this.customFields || [])
        .filter(cf => this.organization && (cf.mode === 'PREDEFINED_LIST' || cf.mode === 'ALPHANUMERIC' && cf.modeOptions?.isRequired || (this.organization[cf.field] && this.organization[cf.field] !== '')))
        .map(cf => ({
          labelKey: cf.nameTranslations[this.locale],
          value: this.getCustomFieldValue(this.customFields, cf.field, this.organization[cf.field]),
          copyable: true,
          i18n: true,
          customField: true,
          isTags: this.isMultiselectField(this.customFields, cf.field),
        }));
    },
    billingMode() {
      return this.organization.billingMode === 'MANUAL' ? this.$t('manual') : this.$t('credit_card');
    },
    hasBillingPermission() {
      return authz.hasPermission('reseller:billing');
    },
    attributes() {
      const billableEnd = this.billableOrgInfo.billableEnd ? this.billableOrgInfo.billableEnd : this.$t('monetization.no_end_date');
      let billingAttributes = [];
      if (this.organization.isBillable && this.hasBillingPermission) {
        billingAttributes = [
          { labelKey: 'billable_end_date', value: billableEnd, copyable: true },
          { labelKey: 'billing_mode', value: this.billingMode, copyable: true },
          { labelKey: 'pricing_package', value: ((this.billableOrgInfo.pricingPackage || {}).name || {})[this.locale], copyable: true },
        ];
      }

      return [
        { labelKey: 'id', value: this.organization.id, copyable: true },
        { labelKey: 'org_code', value: this.organization.entryPoint, copyable: true },
        { labelKey: 'auth_method',
          value: this.authTags,
          copyable: false,
          isTags: true },
        { labelKey: 'custom_domain',
          value: this.organization.customDomain ? this.organization.customDomain.domain : this.$t('none'),
          copyable: true },
        ...billingAttributes,
        ...(this.organization.offerType ? [{ labelKey: 'offer_type', value: this.organization.offerType, copyable: true }] : []),
        ...this.customFieldAttributes,
      ];
    },
    oidcAuths() {
      return (this.identityProviders || []).filter(ip => ip.type === 'OIDC')
        .sort((a, b) => a.rank - b.rank);
    },
    authTags() {
      const oidcCount = this.oidcAuths.length;
      let oidcItem = [];
      if (oidcCount !== 0) {
        oidcItem = [
          {
            id: 'oidc',
            name: this.$t('oidc') + (oidcCount > 1 ? ` (${oidcCount})` : ''),
            isOIDC: true,
          },
        ];
      }
      const orgAuthMethod = this.organization.isDbAuthentication ? 'db_authentication' : 'LDAP';
      return [
        {
          id: orgAuthMethod,
          name: this.$t(orgAuthMethod),
        },
        ...oidcItem,
      ].map((a) => {
        a.sizeClass = 'sm';
        a.colorClass = 'gray';
        a.isHtml = true;
        return a;
      });
    },
    tags() {
      return this.organization.tags ?
        [...this.organization.tags].sort(sortBy(t => t.name))
          .sort(sortBy(t => !t.system))
          .map((t) => {
            if (t.system) {
              t.colorClass = 'readonly';
            }
            return t;
          })
        : [];
    },
    summary() {
      return [{
        label: 'activity',
        sparkline: { activity: this.activity },
      }, {
        label: 'created',
        value: this.$date(this.organization.creationDate, 'YYYY-MM-DD'),
      }, {
        label: 'tags',
        tags: this.tags,
      }, {
        label: 'billable_start_date',
        value: this.$date(this.billableOrgInfo.billableStart || this.organization.billableStartDate, 'YYYY-MM-DD'),
      }].filter(s => s.label !== 'billable_start_date' || this.organization.isBillable)
        .filter(s => s.label !== 'tags'
                || (this.tags && this.tags.length > 0 && authz.hasPermission('reseller:orgsMeta')));
    },
  },
  async created() {
    const resp = await apis.organizations
      .getIdentityProviders(this.organization.id);
    if (resp.status === 200 && resp.data) {
      this.identityProviders = resp.data;
    }
    const resp2 = await apis.organizations.getCustomFields(this.organization.id);
    if (resp2.status === 200 && resp2.data) {
      this.customFields = resp2.data;
    }
    if (this.organization.isBillable && this.hasBillingPermission) {
      await this.fetchBillableOrganizationInfo();
    }
    this.loading = false;
  },
  methods: {
    async fetchBillableOrganizationInfo() {
      const resp = await apis.billable.getBillableOrgInfo(this.organization.id);
      if (resp.status !== 200 && resp.status !== 404) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.billableOrgInfo = resp.data || {};
      }
    },
  },
};
</script>
<style scoped lang="scss">
.notes-container {
  margin-bottom: 20px;
  text-align: left;
  .notes {
    overflow-wrap: break-word;

  }
}
.logo {
  max-height: 30px;
}

.name-plus-logo {
  display: flex;
  align-items: center;
}

.display-name {
  padding-left: 10px;
}

.sparkline {
  display: flex;
  width: 96px;
  height: 32px;
  position: relative;
}

.attribute-tags {
  display: inline-flex;
  padding-right: 4px;
}
</style>
