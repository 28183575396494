<template>
  <div 
    class="list row center-md" 
    :aria-label="$t(ariaLabel)" 
    role="table" 
    :class="{ 'hide-rows': (loading && !enableInfiniteScroll && loadDelay !== 0), 'no-actions': noActions, bordered, 'promoted-operations': promotedOperations }" 
    :aria-rowcount="ariaRowCount"
  >
    <div 
      class="col-xs-12 col-md-12 no-padding" 
      role="rowgroup"
    >
      <div :class="loading && loadDelay === 0 ? 'blur' : undefined">
        <slot></slot>
      </div>
      <div :class="loading && loadDelay === 0 ? 'loader-container' : undefined">
        <base-loader 
          v-if="loading" 
          :delay="loadDelay"
        ></base-loader>
      </div>
      <alert-box 
        v-if="error" 
        alertType="ERROR" 
        :label="error"
      />
      <slot 
        v-else-if="showEmpty"
        name="empty"
      ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseList',
  props: {
    loading: {
      type: Boolean,
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    ariaRowCount: {
      type: Number,
      required: true,
    },
    loadDelay: {
      type: Number,
      default: undefined,
    },
    enableInfiniteScroll: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    bordered: {
      type: Boolean,
    },
    promotedOperations: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    showEmpty() {
      return !this.loading && !(this.list || []).length;
    },
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/mixins.scss';

.no-padding {
  padding: 0;
  position: relative;
}

.blur {
  filter: blur(4px);
}

.loader-container {
    position: absolute;
    top: 50%;
    opacity: 1;
    z-index: 700;
    width: 100%;
    transform: translate(0%, -50%);
}

.list.hide-rows {
  :deep(.list-row) {
     display: none;
  }
}
.list {
  &.bordered {
    @include not-phone {
      border-width: 1px;
      border-style: solid;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

:deep(.action-column) {
  .action-icon {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.alert-box {
  margin: 40px;
  justify-content: center;
}

.no-actions {
  :deep(.action-column) {
    display: none;
  }
}

.promoted-operations {
  :deep(.action-column) {
    flex-basis: 180px;
  }
}
</style>
