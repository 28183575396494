<template>
  <form-page 
    :disabled="disabled" 
    :executing="executing" 
    :cancelLabel="cancelLabel" 
    :hideCancel="disabled" 
    :hideSubmit="disabled"
    @submit="submit" 
    @cancel="$emit('cancel')"
  >
    <base-list 
      ariaLabel="service_connections" 
      :ariaRowCount="totalRows"
    >
      <list-header noActions>
        <div 
          v-if="!disabled" 
          class="checkbox-offset"
        ></div>
        <list-column>{{ $t('service_connection') }}</list-column>
        <list-column>{{ $t('state') }}</list-column>
        <list-column>{{ $t('quota') }}</list-column>
        <list-column>{{ $t('cloud_customer') }}</list-column>
      </list-header>
      <ConnectionAssignmentRow 
        v-for="sc in connections" 
        :key="sc.id+componentKey"
        :readonly="disabled" 
        :assigned="connectionIsAssigned(sc)" 
        :connection="sc" 
        @updateConnection="updateConnection" 
        @addConnection="addConnection" 
        @removeConnection="removeConnection"
      />
      <empty-message 
        v-if="connections.length < 1" 
        icon="fa fa-plug" 
        textLabel="no_available_connections"
      ></empty-message>
    </base-list>
  </form-page>
</template>
<script>
import ConnectionAssignmentRow from './ConnectionAssignmentRow';

export default {
  name: 'OrganizationServicesForm',
  components: { ConnectionAssignmentRow },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    availableConnections: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    executing: {
      type: Boolean,
    },
    cancelLabel: {
      type: String,
    },
    componentKey: {
      type: Number,
      required: true,
    },
  },
  emits: ['submit', 'cancel'],
  data() {
    return {
      selectedConnections: [],
    };
  },
  computed: {
    assignedConnections() {
      return [...this.organization.serviceConnections];
    },
    assignedConnectionState() {
      return this.assignedConnections.reduce((acc, a) => {
        acc[a.id] = a.state;
        return acc;
      }, {});
    },
    connectionQuotas() {
      return (this.organization.quotas || []).reduce((acc, q) => {
        acc[q.serviceConnection.id] = q.id;
        return acc;
      }, {});
    },
    connections() {
      return this.availableConnections.map(sc => ({
        ...sc,
        quotaId: this.quotaOrDefault(sc),
        state: this.assignedConnectionState[sc.id],
      }));
    },
    totalRows() {
      return this.connections.filter(c => this.connectionIsAssigned(c) || !this.disabled).length;
    },
  },
  methods: {
    connectionIsAssigned(connection) {
      return !!this.organization.serviceConnections.find(sc => sc.id === connection.id);
    },
    submit() {
      this.$emit('submit', this.selectedConnections);
      this.selectedConnections = [];
    },
    quotaOrDefault(sc) {
      if (this.connectionQuotas[sc.id]) {
        return this.connectionQuotas[sc.id];
      } else if (sc.quotas && sc.quotas.length > 0) {
        return sc.quotas[0].id;
      }
      return undefined;
    },
    addConnection(connection) {
      this.selectedConnections = [...this.selectedConnections, connection];
    },
    removeConnection(connectionId) {
      this.selectedConnections = this.selectedConnections
        .filter(s => s.id !== connectionId);
    },
    updateConnection(connection) {
      this.removeConnection(connection.id);
      this.addConnection(connection);
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox-offset {
  padding-right: 40px;
}
</style>
