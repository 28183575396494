import BaseRoute from '@/app/components/BaseRoute';

const RoleList = () => import(/* webpackChunkName: "admin" */ './RoleList');
const AddRole = () => import(/* webpackChunkName: "admin" */ './AddRole');
const EditRole = () => import(/* webpackChunkName: "admin" */ './EditRole');

export default [
  {
    path: 'roles',
    component: BaseRoute,
    meta: {
      protected: ['admin:roles', 'reseller:envRoles'],
      isProtectUnion: false,
      title: () => 'roles',
    },
    children: [
      {
        name: 'roleList',
        path: '',
        component: RoleList,
      },
      {
        name: 'customRoleAdd',
        path: 'add',
        component: AddRole,
        props: { switchOrgRouteName: 'roleList' },
      },
      {
        name: 'customRoleEdit',
        path: ':id/edit',
        component: EditRole,
        meta: {
          changeOrgRedirect: '/admin/roles',
        },
        props: route => ({ id: route.params.id, switchOrgRouteName: 'roleList' }),
      },
    ],
  },
];
